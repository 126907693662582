//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

	import 'video.js/dist/video-js.css'
	import { videoPlayer } from 'vue-video-player'
  export default {
    data() {
      return {
        selectedOption: null,
        sectionOrder: ['social', 'language', 'cognitive', 'physical'],
				ageGroup: this.$languageDictionary.ageGroups[this.$route.params.ageGroup] !== undefined ? this.$route.params.ageGroup : 'two-months',
				section: this.$languageDictionary.sections[this.$route.params.section] !== undefined ? this.$route.params.section : 'social',
				language: window.systemLanguage,
      }
    },
		components: {
			videoPlayer
		},
    inject: {
      $validator: '$validator'
    },
		provide() {
      return {
        $validator: this.$validator,
      }
    },
		computed: {
			canFinish() {
				var answeredAllQuestions = true;
				for (var section in this.$answers[this.ageGroup]) {
					for (var answer in this.$answers[this.ageGroup][section]) {
						if (this.$answers[this.ageGroup][section][answer] === '') {
							answeredAllQuestions = false;
						}
					}
				}
				return answeredAllQuestions && this.sectionOrder.indexOf(this.section) === this.sectionOrder.length-1
			}
		},
    watch: {
			'$route' (to, from) {
				this.ageGroup = this.$languageDictionary.ageGroups[this.$route.params.ageGroup] !== undefined ? this.$route.params.ageGroup : 'two-months'
				this.section = this.$languageDictionary.sections[this.$route.params.section] !== undefined ? this.$route.params.section : 'social'
      }
    },
    created() {

			if (this.$answers[this.ageGroup] == undefined) {
				if (this.section !== 'social') {
					this.$router.push({name: "milestones", params: {'ageGroup': this.ageGroup}})
				}
				this.$set(this.$answers, this.ageGroup, {});
				for (var section in this.$languageDictionary.sections) {
					this.$set(this.$answers[this.ageGroup], section, {});
					for (var questionIndex in this.$languageDictionary.milestones[this.ageGroup][section].questions) {
						this.$set(this.$answers[this.ageGroup][section], questionIndex, '');
					}
				}
			}
    },
    mounted() {

    },
    methods: {
			cancel() {
				return false;
			},
			finish() {
				this.$router.push({ name: 'milestones-summary', params: {'ageGroup': this.ageGroup} });
			},
      previous(e) {
				let currentPosition = this.sectionOrder.indexOf(this.section);
				if (currentPosition == 0) {
					alert("begin")
				}
				else {
					this.$router.push({ name: 'milestones', params: {'ageGroup': this.ageGroup, 'section': this.sectionOrder[currentPosition-1]} });
				}
			},
      next(e) {
				var self = this;
				this.$validator.validateAll().then((result) => {
          if (result) {
						let currentPosition = self.sectionOrder.indexOf(self.section);
						if (currentPosition == self.sectionOrder.length - 1) {
							// alert("END!")
						}
						else {
							self.$router.push({ name: 'milestones', params: {'ageGroup': self.ageGroup, 'section': self.sectionOrder[currentPosition+1]} });
						}
					}
				});
			
			}
    },
  }
