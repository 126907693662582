//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  export default {
    data() {
      return {
				ageGroup: this.$languageDictionary.ageGroups[this.$route.params.ageGroup] !== undefined ? this.$route.params.ageGroup : 'two-months',
				zip: '',
				hasResults: false,
				contact: {},
				errorMessage: '',
      }
    },
	
		computed: {
			answersString() {
				var answersString = '';
				for (var section in this.$languageDictionary.milestones[this.ageGroup]) {
					for (var questionKey in this.$languageDictionary.milestones[this.ageGroup][section].questions) {
						switch (this.$answers[this.ageGroup][section][questionKey]) {
							case 'no':
								answersString += "0"
								break;
							case 'yes':
								answersString += "1"
								break;
							case 'not_sure':
								answersString += "2"
								break;
						}
					}
				}
				return answersString;
			},
			emailBody() {
				var body = `%0d%0a====%0d%0a%0d%0a${this.$languageDictionary.ageGroups[this.ageGroup]} Milestones Summary%0d%0a`;
				var answersGetVar = '';
				for (var section in this.$languageDictionary.milestones[this.ageGroup]) {
					body += `${this.$languageDictionary.sections[section]}%0d%0a`
					for (var questionKey in this.$languageDictionary.milestones[this.ageGroup][section].questions) {
						let question = this.$languageDictionary.milestones[this.ageGroup][section].questions[questionKey];
						body+= ` ${question.question}: ${this.$languageDictionary.answers[this.$answers[this.ageGroup][section][questionKey]]} %0d%0a`
						switch (this.$answers[this.ageGroup][section][questionKey]) {
							case 'no':
								answersGetVar += "0"
								break;
							case 'yes':
								answersGetVar += "1"
								break;
							case 'not_sure':
								answersGetVar += "2"
								break;
						}
					}
					body += "%0d%0a";
				}
				body = `%0d%0a%0d%0a====%0d%0a${window.location.origin}/${window.location.pathname}/?answers=${answersGetVar}${body}`
				return body;
			},
			yesCount() {
				var count = 0;
				for (var section in this.$answers[this.ageGroup]) {
					for (var answer in this.$answers[this.ageGroup][section]) {
						if (this.$answers[this.ageGroup][section][answer] === 'yes') {
							count++;
						}
					}
				}
				return count;
			},
			notYetCount() {
				var count = 0;
				for (var section in this.$answers[this.ageGroup]) {
					for (var answer in this.$answers[this.ageGroup][section]) {
						if (this.$answers[this.ageGroup][section][answer] === 'no') {
							count++;
						}
					}
				}
				return count;
			},
			notSureCount() {
				var count = 0;
				for (var section in this.$answers[this.ageGroup]) {
					for (var answer in this.$answers[this.ageGroup][section]) {
						if (this.$answers[this.ageGroup][section][answer] === 'not_sure') {
							count++;
						}
					}
				}
				return count;
			},
		},
    created() {
			if (this.$route.query.answers !== undefined && this.$route.query.answers.length != 0) {
				this.$set(this.$answers, this.ageGroup, {});
				var answers = this.$route.query.answers.split('');
				var i = 0;
				for (var section in this.$languageDictionary.sections) {
					this.$set(this.$answers[this.ageGroup], section, {});
					for (var questionIndex in this.$languageDictionary.milestones[this.ageGroup][section].questions) {
						var a = 'yes';
						if (answers[i] == undefined) { window.location = "/milestones/"+this.ageGroup; return;  }
						else if (answers[i] == 1) a = 'yes';
						else if (answers[i] == 0) a = 'no';
						else if (answers[i] == 2) a = 'not_sure';
						this.$set(this.$answers[this.ageGroup][section], questionIndex, a);
						i++;
					}
				}
			}
			else if (this.$answers[this.ageGroup] == undefined) {
				this.$set(this.$answers, this.ageGroup, {});
				for (var section in this.$languageDictionary.sections) {
					this.$set(this.$answers[this.ageGroup], section, {});
					for (var questionIndex in this.$languageDictionary.milestones[this.ageGroup][section].questions) {
						this.$set(this.$answers[this.ageGroup][section], questionIndex, '');
					}
				}
			}
    },
    mounted() {
			if (this.$router.currentRoute.fullPath != '/milestone-summary/'+this.ageGroup)
      	this.$router.replace({name: 'milestones-summary', params: {ageGroup: this.ageGroup}});
			this.$router.replace({name: 'milestones-summary', params: {ageGroup: this.ageGroup}, query: {answers: this.answersString}});
    },
    methods: {
      lookupContact() {
				this.zip = isNaN(parseInt(this.zip)) ? '' : parseInt(this.zip);
        if (this.zip.toString().length === 5) {
          let self = this;
          this.isSearching = true;
          axios.get("https://edn.ne.gov/stayingontrackpwa/contactLookup/referralLookup.php?zip="+this.zip, {responseType: 'json',})
              .then(function (response) {
                self.isSearching = false;
                if (response.data.errormsg !== undefined) {
                    self.errorMessage = self.languageDictionary.contact.noMatchesMessage;
                }
                else {
                  self.hasResults = true;
                  self.contact = response.data;
                }
              },
            ).catch(error => {
              console.log('fetch error', error);
              self.isSearching = false;
            });
        }
        else {
          this.errorMessage = this.$languageDictionary.contact.inputError;
        }
			},
			print() {
				window.print();
			},
			bookmark() {
				
			},
    },
  }
