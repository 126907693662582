var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content", attrs: { id: "home" } },
    [
      _vm.$emailCoordinators
        ? _c("headerBox", { attrs: { title: "Early Development Network" } })
        : _c("headerBox", {
            attrs: { title: _vm.$languageDictionary.siteTitle }
          }),
      _vm._v(" "),
      _c("div", { attrs: { id: "start", role: "main" } }, [
        _c("h3", [_vm._v(_vm._s(_vm.$languageDictionary.homeHeader))]),
        _vm._v(" "),
        _c("label", { attrs: { for: "age_group" } }, [
          _vm._v(_vm._s(_vm.$languageDictionary.homeContent))
        ]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.ageGroup,
                expression: "ageGroup"
              }
            ],
            on: {
              change: [
                function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.ageGroup = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                function($event) {
                  return _vm.ageGroupChanged($event)
                }
              ]
            }
          },
          _vm._l(_vm.$languageDictionary.navigation, function(anchor, name) {
            return _c("option", {
              key: name,
              staticClass: "box_wrapper__link",
              domProps: { value: name, innerHTML: _vm._s(anchor) }
            })
          }),
          0
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }