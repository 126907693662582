var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content", attrs: { id: "milestones", role: "main" } },
    [
      _c("headerBox", {
        attrs: {
          title: _vm.$languageDictionary.milestoneChecklist,
          subtitle:
            _vm.$languageDictionary.ageGroups[_vm.ageGroup] +
            " - " +
            _vm.$languageDictionary.sections[_vm.section]
        }
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "milestone_section",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.cancel()
            }
          }
        },
        [
          _c("h3", [_vm._v(_vm._s(_vm.$languageDictionary.myChild) + "...")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "milestone_section_questions" },
            _vm._l(
              _vm.$languageDictionary.milestones[_vm.ageGroup][_vm.section]
                .questions,
              function(question, questionKey) {
                return _c(
                  "div",
                  {
                    key: questionKey,
                    staticClass: "milestone_section_question_wrapper"
                  },
                  [
                    _c("div", {
                      staticClass: "question",
                      domProps: { innerHTML: _vm._s(question.question) }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "answer" },
                      [
                        _vm._l(_vm.$languageDictionary.answers, function(
                          answer,
                          answerKey
                        ) {
                          return _c(
                            "label",
                            {
                              key:
                                _vm.ageGroup +
                                "" +
                                _vm.section +
                                "" +
                                questionKey +
                                "" +
                                answerKey,
                              attrs: {
                                for:
                                  "answer" +
                                  _vm.ageGroup +
                                  _vm.section +
                                  questionKey +
                                  answerKey
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.$answers[_vm.ageGroup][_vm.section][
                                        questionKey
                                      ],
                                    expression:
                                      "$answers[ageGroup][section][questionKey]"
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'"
                                  }
                                ],
                                attrs: {
                                  type: "radio",
                                  name:
                                    "answer[" +
                                    _vm.ageGroup +
                                    "][" +
                                    _vm.section +
                                    "][" +
                                    questionKey +
                                    "]",
                                  id:
                                    "answer" +
                                    _vm.ageGroup +
                                    _vm.section +
                                    questionKey +
                                    answerKey,
                                  "data-vv-as": question.question
                                },
                                domProps: {
                                  value: answerKey,
                                  checked: _vm._q(
                                    _vm.$answers[_vm.ageGroup][_vm.section][
                                      questionKey
                                    ],
                                    answerKey
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.$answers[_vm.ageGroup][_vm.section],
                                      questionKey,
                                      answerKey
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(answer))])
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has(
                                  "answer[" +
                                    _vm.ageGroup +
                                    "][" +
                                    _vm.section +
                                    "][" +
                                    questionKey +
                                    "]"
                                ),
                                expression:
                                  "errors.has(`answer[${ageGroup}][${section}][${questionKey}]`)"
                              }
                            ],
                            staticClass: "note error"
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.errors.first(
                                  "answer[" +
                                    _vm.ageGroup +
                                    "][" +
                                    _vm.section +
                                    "][" +
                                    questionKey +
                                    "]"
                                )
                              )
                            )
                          ]
                        )
                      ],
                      2
                    ),
                    _vm._v(" "),
                    question.media.indexOf("vimeo") !== -1
                      ? _c("div", { staticClass: "media iframe video" }, [
                          _c("iframe", {
                            attrs: {
                              src: question.media,
                              frameborder: "0",
                              allow: "autoplay; fullscreen; picture-in-picture",
                              allowfullscreen: ""
                            }
                          })
                        ])
                      : question.media.indexOf("youtube") !== -1
                      ? _c("div", { staticClass: "media iframe video" }, [
                          _c("iframe", {
                            attrs: {
                              src: question.media,
                              frameborder: "0",
                              allow:
                                "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
                              allowfullscreen: ""
                            }
                          })
                        ])
                      : question.media.indexOf("mp4") !== -1
                      ? _c(
                          "div",
                          { staticClass: "media iframe video" },
                          [
                            _c("video-player", {
                              ref: "videoPlayer",
                              refInFor: true,
                              staticClass: "video-player-box",
                              attrs: {
                                options: {
                                  muted: false,
                                  language: "en",
                                  sources: [
                                    {
                                      type: "video/mp4",
                                      src: "/vids/" + question.media,
                                      currentTime: "3"
                                    }
                                  ]
                                },
                                currentTime: "3",
                                playsinline: true
                              }
                            })
                          ],
                          1
                        )
                      : question.media != "" &&
                        question.media != "/imgs/milestones/en/" &&
                        question.media != "/imgs/milestones/es/"
                      ? _c("div", { staticClass: "media img" }, [
                          _c("img", {
                            attrs: {
                              src:
                                "/imgs/milestones/" +
                                _vm.language +
                                "/" +
                                question.media,
                              alt: question.question
                            }
                          })
                        ])
                      : _vm._e()
                  ]
                )
              }
            ),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "nav" }, [
            _c(
              "button",
              {
                attrs: {
                  disabled: this.sectionOrder.indexOf(this.section) === 0
                },
                on: {
                  click: function($event) {
                    return _vm.previous()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$languageDictionary.previous))]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                attrs: {
                  disabled:
                    this.sectionOrder.indexOf(this.section) ===
                    this.sectionOrder.length - 1
                },
                on: {
                  click: function($event) {
                    return _vm.next()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$languageDictionary.next))]
            ),
            _vm._v(" "),
            this.sectionOrder.indexOf(this.section) ===
            this.sectionOrder.length - 1
              ? _c(
                  "button",
                  {
                    staticClass: "finish",
                    attrs: { disabled: !_vm.canFinish },
                    on: {
                      click: function($event) {
                        return _vm.finish()
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$languageDictionary.finish))]
                )
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }