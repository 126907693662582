//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import { EventBus } from '../eventbus.js';
  import Vue from 'vue';

  export default {
    name: 'app',
		props: {
			languageDictionary: Object,
		},
    data() {
      return {
				answers: {},
				emailCoordinators: false,
      }
    },
    watch: {
      '$route' (to, from) {
        window.scrollTo(0,0);
      }
    },
    created() {
			if (parseInt(this.$route.query.emailCoordinators) == 1) {
				this.emailCoordinators = true;
			}

			Vue.prototype.$emailCoordinators = this.emailCoordinators;
      
			Vue.prototype.$ageGroups = this.languageDictionary.ageGroups;
			Vue.prototype.$languageDictionary = this.languageDictionary;
			Vue.prototype.$answers = this.answers;
    },
    computed: {
      hasSidebar() {
        return this.$route.meta.hasSidebar === true ? true: false;
      },
    },
  }
