var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "tall_header " + _vm.headerColorClass, attrs: { id: "content" } },
    [
      _c("headerBox", {
        attrs: { title: _vm.page.title, headerColorClass: _vm.headerColorClass }
      }),
      _vm._v(" "),
      !_vm.pageNotFound
        ? _c("div", {
            attrs: { role: "main" },
            domProps: { innerHTML: _vm._s(_vm.page.content) }
          })
        : _c("div", { attrs: { id: "notFound", role: "main" } }, [
            _vm._m(0),
            _vm._v(" "),
            _c("h2", [_vm._v("Sorry, we couldn't find that page.")]),
            _vm._v(" "),
            _c("br"),
            _c("br"),
            _vm._v(" "),
            _vm._m(1)
          ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "notFound__icons" } }, [
      _c("div", { staticClass: "fas fa-search icon" }),
      _vm._v(" "),
      _c("div", { staticClass: "fas fa-question icon" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("a", { staticClass: "button", attrs: { href: "/" } }, [
        _vm._v("Home "),
        _c("span", { staticClass: "fas fa-home icon" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }