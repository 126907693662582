var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("div", { attrs: { id: "bg-elements" } }, [
        _c(
          "svg",
          {
            attrs: {
              id: "scribble1",
              xmlns: "http://www.w3.org/2000/svg",
              width: "414.02",
              height: "871.76",
              viewBox: "0 0 414.02 871.76"
            }
          },
          [
            _c("title", [_vm._v("Asset 1")]),
            _c("path", {
              attrs: {
                d:
                  "M3,82.43C5.73,49.92,28.81,20.1,59.86,9.78c39.32-13.08,80.28,4.2,116,19.87C193.41,37.33,211,45.07,229.56,50a209.84,209.84,0,0,0,54.17,6.88,214.23,214.23,0,0,0,54.89-7.33A206.41,206.41,0,0,0,365,40.46c7.18-3,14.26-6.19,19.88-11.71,4.15-4.08,8-9.87,6.82-16-1.41-7-8.22-10.23-14.64-11.45-12.95-2.49-27.51-1.59-39.64,3.8s-21,16.42-21.21,30.11c-.23,15.64,9.65,30.17,21,40.06,13.85,12,30.81,20.08,43.61,33.35,11.61,12,18.76,27.41,23,43.44,4.33,16.54,5.79,33.68,6.54,50.7.79,18,2.71,37.67-3.73,54.85C394.92,289,355.34,300.93,325,303c-29.39,2-61.65-5.09-82.67-27C230.68,263.76,225.56,248,218,233.3c-6.89-13.41-16.66-24.32-31.2-29.35-29.7-10.29-60.68,4.77-79,28.3C87,258.82,77.69,294.11,74.64,327.17c-3.33,36,1.56,72.68,13,106.94,21.91,65.53,68.11,120.12,124.06,159.62A413.92,413.92,0,0,0,256,621c14.88,7.92,31.38,14.47,43.41,26.67C322.5,671,313.77,707,293.69,729.17c-24.34,26.89-63.27,28.32-92.61,47.23-12.53,8.08-23.89,19.32-28.52,33.83-2,6.32-3.41,14.31.22,20.31,4.17,6.89,13.76,9.92,20.56,13.3l55.86,27.75c1.73.86,3.25-1.73,1.52-2.59l-56.64-28.13c-8.36-4.16-20.11-7.67-20.5-18.71-.29-8.1,3.31-16.48,7.72-23.09,20.31-30.46,60.34-34.06,90.52-49.63,24.36-12.57,43.53-37.59,44.55-65.68a51.07,51.07,0,0,0-13-36.3c-10.71-11.92-25.71-18.71-39.71-25.88C203.29,590.65,149.2,545,114.6,486.13c-36.42-62-49.49-139.57-26.66-208.66,10.1-30.59,29.56-63.06,62.87-71.58,15-3.82,31.74-2.69,45,5.65,14.57,9.14,20.69,25.59,27.59,40.49,6.15,13.29,14,25,25.74,34,10.94,8.39,23.87,13.91,37.26,17,27.72,6.41,58.2,3.4,84.28-7.87,13.67-5.91,26.65-14.78,34.5-27.72,9.06-15,9.11-32.91,8.67-49.84-.81-31.12-2-64.62-17.33-92.58-7.81-14.24-19.28-24.78-32.48-34C349.17,80.67,333,71.11,324.29,54.5c-7.8-14.87-6.66-33.08,8-43.13C344.13,3.25,360.15,1.85,374,3.85c4.1.59,8.74,1.49,11.86,4.43,4,3.76,3.55,9,.92,13.41-4.46,7.43-12.54,11.48-20.17,14.83a206,206,0,0,1-53.2,15.15c-38,5.54-75.84,0-111.39-14.14-35.3-14-69.65-33.65-108.52-35.16-32.17-1.25-62,12.92-79.76,40.12A86.45,86.45,0,0,0,0,82.43c-.17,1.92,2.84,1.91,3,0Z"
              }
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "svg",
          {
            staticClass: "shape",
            attrs: {
              id: "circle",
              xmlns: "http://www.w3.org/2000/svg",
              width: "16",
              height: "16",
              viewBox: "0 0 16 16"
            }
          },
          [
            _c("title", [_vm._v("Asset 2")]),
            _c("circle", { attrs: { cx: "8", cy: "8", r: "8" } })
          ]
        ),
        _vm._v(" "),
        _c(
          "svg",
          {
            staticClass: "shape",
            attrs: {
              id: "hex",
              xmlns: "http://www.w3.org/2000/svg",
              width: "39.52",
              height: "42.44",
              viewBox: "0 0 39.52 42.44"
            }
          },
          [
            _c("title", [_vm._v("Asset 3")]),
            _c("polygon", {
              attrs: {
                points:
                  "36.76 34.22 17 42.45 0 29.45 2.76 8.22 22.52 0 39.52 13 36.76 34.22"
              }
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "svg",
          {
            class: { shape: true, on_home: _vm.$route.name == "home" },
            attrs: {
              id: "star",
              xmlns: "http://www.w3.org/2000/svg",
              width: "40.55",
              height: "41.81",
              viewBox: "0 0 40.55 41.81"
            }
          },
          [
            _c("title", [_vm._v("Asset 4")]),
            _c("polygon", {
              attrs: {
                points:
                  "38.98 35.32 24.74 31.98 13.92 41.81 12.7 27.24 0 19.98 13.48 14.32 16.46 0 26.01 11.07 40.55 9.48 32.97 21.99 38.98 35.32"
              }
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "svg",
          {
            staticClass: "shape",
            attrs: {
              id: "square",
              xmlns: "http://www.w3.org/2000/svg",
              width: "28",
              height: "28",
              viewBox: "0 0 28 28"
            }
          },
          [
            _c("title", [_vm._v("Asset 5")]),
            _c("rect", { attrs: { width: "28", height: "28" } })
          ]
        ),
        _vm._v(" "),
        _c(
          "svg",
          {
            staticClass: "shape",
            attrs: {
              id: "circle2",
              xmlns: "http://www.w3.org/2000/svg",
              width: "16",
              height: "16",
              viewBox: "0 0 16 16"
            }
          },
          [
            _c("title", [_vm._v("Asset 2")]),
            _c("circle", { attrs: { cx: "8", cy: "8", r: "8" } })
          ]
        ),
        _vm._v(" "),
        _c(
          "svg",
          {
            staticClass: "shape",
            attrs: {
              id: "hex2",
              xmlns: "http://www.w3.org/2000/svg",
              width: "39.52",
              height: "42.44",
              viewBox: "0 0 39.52 42.44"
            }
          },
          [
            _c("title", [_vm._v("Asset 3")]),
            _c("polygon", {
              attrs: {
                points:
                  "36.76 34.22 17 42.45 0 29.45 2.76 8.22 22.52 0 39.52 13 36.76 34.22"
              }
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "svg",
          {
            staticClass: "shape",
            attrs: {
              id: "star2",
              xmlns: "http://www.w3.org/2000/svg",
              width: "40.55",
              height: "41.81",
              viewBox: "0 0 40.55 41.81"
            }
          },
          [
            _c("title", [_vm._v("Asset 4")]),
            _c("polygon", {
              attrs: {
                points:
                  "38.98 35.32 24.74 31.98 13.92 41.81 12.7 27.24 0 19.98 13.48 14.32 16.46 0 26.01 11.07 40.55 9.48 32.97 21.99 38.98 35.32"
              }
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "svg",
          {
            staticClass: "shape",
            attrs: {
              id: "square2",
              xmlns: "http://www.w3.org/2000/svg",
              width: "28",
              height: "28",
              viewBox: "0 0 28 28"
            }
          },
          [
            _c("title", [_vm._v("Asset 5")]),
            _c("rect", { attrs: { width: "28", height: "28" } })
          ]
        )
      ]),
      _vm._v(" "),
      _vm.languageDictionary !== null
        ? [
            _c("router-view"),
            _vm._v(" "),
            _c("div", {
              staticClass: "disclaimer",
              domProps: { innerHTML: _vm._s(_vm.languageDictionary.disclaimer) }
            })
          ]
        : _c("div", { attrs: { id: "loading", role: "main" } }, [
            _vm.emailCoordinators
              ? _c("h1", [_vm._v("Early Development Network")])
              : _c("h1", [_vm._v("Help Me Grow")]),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "loading" })
          ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "spinner fa-spin" }, [
      _c("div", { staticClass: "circle colorPink" }),
      _vm._v(" "),
      _c("div", { staticClass: "circle colorOrange" }),
      _vm._v(" "),
      _c("div", { staticClass: "circle colorYellow" }),
      _vm._v(" "),
      _c("div", { staticClass: "circle colorGreen" }),
      _vm._v(" "),
      _c("div", { staticClass: "circle colorBlue" }),
      _vm._v(" "),
      _c("div", { staticClass: "circle colorPurple" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }