//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      page: {},
      headerColorClass: 'colorBlue',
      pageNotFound: false,
    }
  },
  props: {
    languageDictionary: Object,
  },
  created() {
    if (this.languageDictionary[this.$route.name] !== undefined) {
      this.page = this.languageDictionary[this.$route.name].index;
      this.headerColorClass = this.languageDictionary[this.$route.name].strings.headerColorClass;
    }
    else {
      this.page.title = "Page Not Found";
      this.page.content = "Sorry";
      this.pageNotFound = true;
    }
  },
  mounted() {
    window.updateLinks();
  },
}
