import Vue from 'vue';
import VueRouter from 'vue-router';
import VeeValidate from 'vee-validate';

Vue.use(VueRouter);

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let getUrlLanguage = window.location.search.substring(1).replace(/(?:(?:^|.*;\s*)language\s*\=\s*([^;]*).*$)|^.*$/, "$1");
if (getUrlLanguage == 'es' || getUrlLanguage == 'en') {
	var res = document.cookie;
	var multiple = res.split(";");
	for(var i = 0; i < multiple.length; i++) {
		 var key = multiple[i].split("=");
		 document.cookie = key[0]+" =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
	}
	document.cookie = "language="+getUrlLanguage+";";
}

let cookieLanguage = document.cookie.replace(/(?:(?:^|.*;\s*)language\s*\=\s*([^;]*).*$)|^.*$/, "$1");

if (cookieLanguage != 'es' && cookieLanguage != 'en') {
  window.systemLanguage = 'en';
}
else {
  window.systemLanguage = cookieLanguage;
}

import App from './components/App.vue';

import {routes} from './routes.js';

import headerBox from './components/HeaderBox.vue';
Vue.component('headerBox', headerBox);

var self = this, 
	languageDictionary = {};
	axios.get('/languages/'+window.systemLanguage+"/index.php")
	.then(function (response) {
		languageDictionary = response.data;
		const router = new VueRouter({
			mode: 'history',
			routes: routes,
		});

		var dictionary = {};
		dictionary[window.systemLanguage] = {
			messages: {required: (field) => eval(languageDictionary.fieldRequiredMessage),}
		}
		Vue.use(VeeValidate, {
			locale: window.systemLanguage,
			dictionary: dictionary,
		});

		new Vue({
			el: "#app",
			router,
			template: '<App/>',
			components: { App },
			render: h => h(App, {props: {languageDictionary: languageDictionary} } )
		});
	},
).catch(error => {
	console.log('language fetch error', error);
});



import { EventBus } from './eventbus.js';
