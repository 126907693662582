import DisplayPage from './components/DisplayPage.vue'
import Home from './components/Home.vue'
import Milestones from './components/Milestones.vue'
import MilestonesSummary from './components/MilestonesSummary.vue'
import Contact from './components/Contact.vue'

export const routes = [
  {
    path: '/',
    component: Home,
    name: 'home',
  },
  {
    path: '/index.php',
    component: Home,
    name: 'home2',
  },
  {
    path: '/contact',
    component: Contact,
    name: 'contact',
  },
  {
    path: '/milestone-summary/:ageGroup',
    component: MilestonesSummary,
    name: 'milestones-summary',
  },
  {
    path: '/milestones/:ageGroup?/:section?',
    component: Milestones,
    name: 'milestones',
  },
  //don't really like this since we can't return a legit 404 header
  {
    path: '/404',
    component: DisplayPage
  },
  {
    path: '*',
    redirect: '/404'
  },
];
