//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  export default {
		data() {
			return {
				systemLanguage: window.systemLanguage,
			}
		},
    props: {
      title: String,
      subtitle: String,
			language: {
				type: String,
				default: window.systemLanguage,
			}
    },
    methods: {
      goHome(event) {
        event.preventDefault();
        event.stopPropagation();
        this.$router.push({ name: 'home' });
        return false;
      },
      languageChanged() {
        window.location = "/?language="+this.systemLanguage;
      }
    }
  }
