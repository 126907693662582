//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Contact",
  data() {
    return {
      hasResults: false,
      isSearching: false,
      zip: '',
      contact: {},
      errorMessage: '',
      isOnline: false,
    }
  },
  props: {
    languageDictionary: Object,
  },
  watch: {
    '$route' (to, from) {
      //this.showOption(to.params.age);
    }
  },
  created() {
    this.headerColorClass = this.languageDictionary[this.$route.name].strings.headerColorClass;
    if (navigator.onLine) {
      this.isOnline = true;
    } else {
      this.isOnline = false;
    }
    let self = this;
    window.addEventListener('online', () => {
      self.isOnline = true;
    });
    window.addEventListener('offline', () => {
      self.isOnline = false;
    });
  },
  mounted() {
    window.updateLinks();
  },
  methods: {
    search(event) {
      if (event !== undefined) {
        event.preventDefault();
      }
      this.errorMessage = '';
      this.zip = parseInt(this.zip);
      if (this.zip.toString().length === 5) {
        let self = this;
        this.isSearching = true;
        axios.get("https://edn.ne.gov/stayingontrackpwa/contactLookup/referralLookup.php?zip="+this.zip, {responseType: 'json',})
            .then(function (response) {
              self.isSearching = false;
              if (response.data.errormsg !== undefined) {
                  self.errorMessage = self.languageDictionary.contact.index.noMatchesMessage;
              }
              else {
                self.hasResults = true;
                self.contact = response.data;
              }
            },
          ).catch(error => {
            console.log('fetch error', error);
            self.isSearching = false;
          });
      }
      else {
        this.errorMessage = this.languageDictionary.contact.index.inputError;
      }
    }
  },
}
