//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


  export default {
    name: "Home",
    data() {
      return {
        systemLanguage: window.systemLanguage,
				ageGroup: '',
      }
    },
    props: {
      languageDictionary: Object,
    },
    methods: {
      ageGroupChanged() {
        this.$router.push({name: 'milestones', params: {ageGroup: this.ageGroup}})
      }
    }
  }
