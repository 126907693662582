var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { attrs: { id: "header" } },
    [
      _c("h1", [
        _c("span", { domProps: { innerHTML: _vm._s(_vm.title) } }, [
          _vm._v(_vm._s(_vm.title))
        ]),
        _vm._v(" "),
        _c("span", {
          staticClass: "sub",
          domProps: { innerHTML: _vm._s(_vm.subtitle) }
        })
      ]),
      _vm._v(" "),
      _vm.$route.name != "home"
        ? _c(
            "router-link",
            {
              attrs: {
                to: { name: "home" },
                id: "back",
                title: _vm.$languageDictionary.startOver
              }
            },
            [_c("span", { staticClass: "fas fa-arrow-left" })]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.systemLanguage,
              expression: "systemLanguage"
            }
          ],
          attrs: {
            id: "languageSelect",
            title: _vm.$languageDictionary.changeLanguage
          },
          on: {
            change: [
              function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.systemLanguage = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
              function($event) {
                return _vm.languageChanged($event)
              }
            ]
          }
        },
        [
          _c(
            "option",
            {
              attrs: { value: "en" },
              domProps: { selected: _vm.systemLanguage == "en" }
            },
            [_vm._v("English")]
          ),
          _vm._v(" "),
          _c(
            "option",
            {
              attrs: { value: "es" },
              domProps: { selected: _vm.systemLanguage == "es" }
            },
            [_vm._v("Español")]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }